export default class NewPaymentMethodFailureEventService {
    static notify(params) {
        const authenticityToken = $('#authenticity_token').val();
        return $.ajax({
            method: "POST",
            url: "/payment_method/failure_event",
            data: params,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': authenticityToken
            },
            error: function (jqxhr, error_type, http_status_text) {
                const msg = error_type + " " + (i18n.t('payment_methods.error.attempting_to_post_failure_event'));
                const context = {
                    url: this.url,
                    data: this.data,
                    error_type: error_type,
                    http_status_text: http_status_text
                };
                return ErrorNotificationService.error(msg, context);
            }
        });
    }
}